<template>
  <div id="print-boking">
    <div class="text-center flex mb-4">
      <b-form-select
        v-model="selectedPage"
        class="select-form"
      >
        <b-form-select-option :value="1">
          {{$t('golf_booking_print_all')}}
        </b-form-select-option>
        <b-form-select-option :value="2">
          {{ $t('golf_booking_print_custom') }}
        </b-form-select-option>
      </b-form-select>
      <b-form-input
        v-if="selectedPage === 2"
        v-model="text"
        placeholder="ví dụ: 1-5, 8, 11-13"
        class="select-form"
        @input="checkvalidate()"
        @keypress="key($event)"
      />
      <b-form-select
        v-model="selected"
        class="select-form"
      >
        <b-form-select-option value="print-tem">
          {{$t('golf_booking_print_tem_50_30')}}
        </b-form-select-option>
        <b-form-select-option value="print-a4">
          {{$t('golf_booking_print_A4_Doc')}}
        </b-form-select-option>
        <b-form-select-option value="print-a4-297">
          {{$t('golf_booking_print_A4_Ngang')}}
        </b-form-select-option>
        <b-form-select-option value="print-a6-80">
          {{$t('golf_booking_print_A4_80mm')}}
        </b-form-select-option>
        <b-form-select-option value="print-a6-58">
          {{$t('golf_booking_print_A4_58mm')}}
        </b-form-select-option>
      </b-form-select>
      <b-button
        variant="primary"
        :disabled="txt_error!==null && txt_error !== '' && selectedPage === 2"
        @click="print(selected)"
      >
        <i class="icon-print-ic fs-16" /> In
      </b-button>
    </div>
    <b-row>
      <b-col
        v-for="(i, index) in listPrint"
        :key="index"
        md="4"
        class="mb-4"
      >
        <template>
          <div class="d-flex item-print">
            <div>
              <vue-qrcode
                :value="i.BDC"
                width="100"
                error-correction-level="L"
                margin="0"
              />
              <p class="m-0 mt-1 fs-12 text-center">
                <strong>{{ i.BDC }}</strong>
              </p>
            </div>
            <div class="ml-2">
              <p class="">
                <strong>{{ i.MemberName }}</strong>
              </p>
              <p>{{ i.TeeName }}</p>
              <p>{{ convertUTC(i.OpenDate,'DD/MM/YYYY ') }}  {{ convertUTC(i.OpenDate,'HH:mm') }}</p>
              <p>BagTag: {{ i.BagTagNumber }}</p>
            </div>
          </div>
          <p class="text-center m-0 mt-2">
            <span style="font-size: 10px">{{ i.index+1 }}/{{ BookingDetail.length }}</span>
          </p>
        </template>
      </b-col>
    </b-row>
    <div
      id="print-a4"
      style="width:210mm; display: none; margin: auto"
    >
      <div
        v-for="(i, index) in listPrint"
        :key="index"
        style="padding: 0 25px"
      >
        <table :style="(index+1)% 2 === 0 ? 'page-break-after:always; width: 80mm;' : 'width: 80mm; float: left;'">
          <td>
            <template>
              <div
                class="d-flex item-print"
                style="padding: 10px; border: 2px dashed; border-radius: 8px; background: #fff; display: flex;"
              >
                <div>
                  <vue-qrcode
                    :value="i.BDC"
                    width="100"
                    error-correction-level="L"
                    margin="0"
                  />
                  <p style="text-align: center; margin-top: 10px">
                    <strong>{{ i.BDC }}</strong>
                  </p>
                </div>
                <div style="margin-left: 15px !important;">
                  <p class="">
                    <strong>{{ i.MemberName }}</strong>
                  </p>
                  <p>{{ i.TeeName }}</p>
                  <p>{{ convertUTC(i.OpenDate,'DD/MM/YYYY ') }}  {{ convertUTC(i.OpenDate,'HH:mm') }}</p>
                  <p>BagTag: {{ i.BagTagNumber }}</p>
                </div>
              </div>
              <p style="text-align: center; margin-bottom: 0; margin-top: 10px">
                <span style="font-size: 10px">{{ index+1 }}/{{ BookingDetail.length }}</span>
              </p>
            </template>
          </td>
        </table>
      </div>
    </div>
    <div
      id="print-a4-297"
      style="width:297mm; display: none; margin: auto"
    >
      <div
        v-for="(i, index) in listPrint"
        :key="index"
        style="padding: 0 25px"
      >
        <table :style="(index+1)% 3 === 0 ? 'page-break-after:always; width: 80mm;' : 'width: 80mm; float: left;'">
          <td>
            <template>
              <div
                class="d-flex item-print"
                style="padding: 10px; border: 2px dashed; border-radius: 8px; background: #fff; display: flex;"
              >
                <div>
                  <vue-qrcode
                    :value="i.BDC"
                    width="100"
                    error-correction-level="L"
                    margin="0"
                  />
                  <p style="text-align: center; margin-top: 10px">
                    <strong>{{ i.BDC }}</strong>
                  </p>
                </div>
                <div style="margin-left: 15px !important;">
                  <p class="">
                    <strong>{{ i.MemberName }}</strong>
                  </p>
                  <p>{{ i.TeeName }}</p>
                  <p>{{ convertUTC(i.OpenDate,'DD/MM/YYYY ') }}  {{ convertUTC(i.OpenDate,'HH:mm') }}</p>
                  <p>BagTag: {{ i.BagTagNumber }}</p>
                </div>
              </div>
              <p style="text-align: center; margin-bottom: 0; margin-top: 10px">
                <span style="font-size: 10px">{{ index+1 }}/{{ BookingDetail.length }}</span>
              </p>
            </template>
          </td>
        </table>
      </div>
    </div>
    <div
      id="print-a6-80"
      style="width: 80mm; display: none; margin: auto"
    >
      <div
        v-for="(i, index) in BookingDetail"
        v-if="ticket_print.includes(+index+1) === true || ticket_print.length === 0"
        :key="index"
        style="margin-bottom: 50px; page-break-after: always;"
      >
        <template>
          <div
            class="d-flex item-print"
            style="padding: 10px; border: 2px dashed; border-radius: 8px; background: #fff; display: flex;"
          >
            <div>
              <vue-qrcode
                :value="i.BDC"
                width="100"
                error-correction-level="L"
                margin="0"
              />
              <p style="text-align: center; margin-top: 10px">
                <strong>{{ i.BDC }}</strong>
              </p>
            </div>
            <div style="margin-left: 15px !important;">
              <p class="">
                <strong>{{ i.MemberName }}</strong>
              </p>
              <p>{{ i.TeeName }}</p>
              <p>{{ convertUTC(i.OpenDate,'DD/MM/YYYY ') }}  {{ convertUTC(i.OpenDate,'HH:mm') }}</p>
              <p>BagTag: {{ i.BagTagNumber }}</p>
            </div>
          </div>
          <p style="text-align: center; margin-bottom: 0; margin-top: 10px">
            <span style="font-size: 10px">{{ index+1 }}/{{ BookingDetail.length }}</span>
          </p>
        </template>
      </div>
    </div>
    <div
      id="print-a6-58"
      style="width:80mm; display: none; margin: auto"
    >
      <div
        v-for="(i, index) in BookingDetail"
        v-if="ticket_print.includes(+index+1) === true || ticket_print.length === 0"
        :key="index"
        style="padding-bottom: 50px; page-break-after: always;"
      >
        <template>
          <div
            class="d-flex item-print"
            style="padding: 10px; border: 2px dashed; border-radius: 8px; background: #fff; display: flex;"
          >
            <div>
              <vue-qrcode
                :value="i.BDC"
                width="100"
                error-correction-level="L"
                margin="0"
              />
              <p style="text-align: center; margin-top: 10px">
                <strong>{{ i.BDC }}</strong>
              </p>
            </div>
            <div style="margin-left: 15px !important;">
              <p class="">
                <strong>{{ i.MemberName }}</strong>
              </p>
              <p>{{ i.TeeName }}</p>
              <p>{{ convertUTC(i.OpenDate,'DD/MM/YYYY ') }}  {{ convertUTC(i.OpenDate,'HH:mm') }}</p>
              <p>BagTag: {{ i.BagTagNumber }}</p>
            </div>
          </div>
          <p style="text-align: center; margin-bottom: 0; margin-top: 10px">
            <span style="font-size: 10px">{{ index+1 }}/{{ BookingDetail.length }}</span>
          </p>
        </template>
      </div>
    </div>
    <div
      id="print-tem"
      style="width:100mm; display: none; margin: auto; height: 30mm;align-items: center;"
    >

      <div
        v-for="(i, index) in BookingDetail"
        v-if="ticket_print.includes(+index+1) === true || ticket_print.length === 0"
        :key="index"
        style="page-break-after: always;"
      >
        <template >
          <div
            style="display: flex; justify-content: center; align-items: center;margin-top:2mm"
          >
          <!-- div 1 -->
          <div>
            <div  style="display: flex; width: 47mm; height: 24mm; justify-content: center; align-items: center;">
            <div style="text-align: center;">
              <vue-qrcode
                :value="i.BDC"
                width="55"
                error-correction-level="L"
                margin="0"
              />
              <p style="text-align: center; margin-top: 5px; margin-bottom: 0; font-size: 11px">
                <strong>{{ i.BDC }}</strong>
              </p>
            </div>
            <div style="margin: 0 8px !important; font-size: 11px">
              <p style="margin-bottom: 0">
                <strong style="font-size: 11px">{{ i.MemberName }}</strong>
              </p>
              <p style="margin-bottom: 0 ;font-size: 11px">{{ i.TeeName }}</p>
              <p style="margin-bottom: 0 ;font-size: 11px">{{ convertUTC(i.OpenDate,'DD/MM/YYYY ') }}  {{ convertUTC(i.OpenDate,'HH:mm') }}</p>
              <p style="margin-bottom: 0 ;font-size: 11px">BagTag: {{ i.BagTagNumber }}</p>
            </div>
          </div>
          <div style="height: 3mm;">
              <p style="font-size: 9px;margin-bottom: 0;text-align: center;">Powered by ezCloud</p>
          </div>
          </div>
          <div style="width:6mm;height: 27mm"></div>
          <!-- div 2 -->
          <div>
            <div  style="display: flex; width: 47mm; height: 24mm; justify-content: center; align-items: center;">
            <div style="text-align: center;">
              <vue-qrcode
                :value="i.BDC"
                width="55"
                error-correction-level="L"
                margin="0"
              />
              <p style="text-align: center; margin-top: 5px; margin-bottom: 0; font-size: 11px">
                <strong>{{ i.BDC }}</strong>
              </p>
            </div>
            <div style="margin: 0 8px !important;  font-size: 11px">
              <p style="margin-bottom: 0">
                <strong style="font-size: 11px">{{ i.MemberName }}</strong>
              </p>
              <p style="margin-bottom: 0 ;font-size: 11px">{{ i.TeeName }}</p>
              <p style="margin-bottom: 0 ;font-size: 11px">{{ convertUTC(i.OpenDate,'DD/MM/YYYY ') }}  {{ convertUTC(i.OpenDate,'HH:mm') }}</p>
              <p style="margin-bottom: 0 ;font-size: 11px">BagTag: {{ i.BagTagNumber }}</p>
            </div>
          </div>
          <div style="height: 3mm;">
              <p style="font-size: 9px;margin-bottom: 0;text-align: center;">Powered by ezCloud</p>
          </div>
          </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {BButton,VBModal, BRow, BCol, VBToggle, BFormInput} from 'bootstrap-vue'
import VueQrcode from 'vue-qrcode'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import dayjs from 'dayjs'
import vSelect from 'vue-select'
import { bookingPrint } from '@/api/booking-print'

const customParseFormat = require('dayjs/plugin/customParseFormat')

dayjs.extend(customParseFormat)

export default {
  name: 'Booking',
  components: {
    VBModal,
    VBToggle,
    vSelect,
    flatPickr,
    VueQrcode,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-modal': VBModal,
  },
  data() {
    return {
      ids: [],
      BookingDetail: [],
      listPrint: [],
      selectedPage: 1,
      text: null,
			  txt_error: null,
      selected: 'print-tem',
      ticket_print: [],
			  listPrint: [],
    }
  },

  watch: {
    selectedPage() {
      this.text = null
      this.txt_error = null
      this.ticket_print = []
    },
    ticket_print() {
      if (this.ticket_print.length === 0) {
        this.listPrint = []
        this.BookingDetail.forEach((element, index) => {
          const ob = { index, ...element }
          this.listPrint.push(ob)
        })
      } else {
        this.listPrint = []
        this.BookingDetail.forEach((element, index) => {
          if (this.ticket_print.includes(+index + 1) === true) {
            const ob = { index, ...element }
            this.listPrint.push(ob)
          }
        })
      }
    },
  },
  created() {
    this.getList()
  },

  methods: {
    getList() {
      if (this.$route.query && this.$route.query.id && this.$route.query.id.length > 0 && typeof this.$route.query.id !== 'string') {
        this.ids = [...this.$route.query.id]
      } else if (this.$route.query && this.$route.query.id && this.$route.query.id.length > 0 && typeof this.$route.query.id === 'string') {
        this.ids = [this.$route.query.id]
      }
      const boby = {
        BookingDetailId: this.ids.map(e => +e),
      }
      bookingPrint
        .getListDetailBooking(boby)
        .then(res => {
          if (res) {
            this.BookingDetail = res.Data.BookingDetail
            this.listPrint = []
            this.BookingDetail.forEach((element, index) => {
              const ob = { index, ...element }
              this.listPrint.push(ob)
            })
            setTimeout(() => {
              this.print(this.selected)
            },1000)
            setTimeout(() => {
              window.close()
            },4000)
          }
        })
    },
    print(e) {
      const divContents = document.getElementById(e).innerHTML
      const printWindow = window.open('', '', 'height=500,width=1000')
      printWindow.document.write('<html><head><title>ezCloud_ezGolf</title>')
      printWindow.document.write('</head><body style="padding: 0px;margin: 0px;">')
      printWindow.document.write('<style >  @media print { @page { margin:0; } *{font-family:  Arial, Helvetica, sans-serif ;} p{margin:0;}} ')
      printWindow.document.write('</style>')
      printWindow.document.write(divContents)
      printWindow.document.write('</body></html>')
      setTimeout(() => {
        printWindow.print()
      },1000)
    },
    checkvalidate() {
      this.ticket_print = []
      setTimeout(() => {
        const tickets = []
        const arr = this.text.split(',')
        this.txt_error = null
        if (this.text !== '' && this.text !== null) {
          arr.forEach((element, index) => {
            const txt = element.trim()
            if (isNaN(txt) && txt.indexOf('-') > -1 && txt.indexOf('-') !== 0) { // chuỗi có dấu -
              const item = txt.split('-')
              const arr_item = []
              item.forEach(e => {
                if (isNaN(e.trim())) { // là chữ
                  this.txt_error = this.$t('ERROR_TICKET_PRINT')
                } else {
                  arr_item.push(e)
                }
              })
              if (arr_item.length > 0) {
                if (+arr_item[0] > +arr_item[1] || +arr_item[0] <= 0) {
                  this.txt_error = this.$t('ERROR_TICKET_PRINT')
                } else {
                  for (let i = +arr_item[0]; i <= arr_item[1]; i += 1) {
                    tickets.push(i)
                  }
                }
              }
            } else if (!isNaN(txt)) { // hợp lệ là 1 số
              if (+txt > 0) {
                tickets.push(+element)
              } else {
                this.txt_error = this.$t('ERROR_TICKET_PRINT')
              }
            } else if (isNaN(txt) && txt.indexOf('-') === -1) { // chữ nhưng không có dấu -
              this.txt_error = this.$t('ERROR_TICKET_PRINT')
            } else if (isNaN(txt) && txt.indexOf('-') > -1 && txt.indexOf('-') === 0) {
              this.txt_error = this.$t('ERROR_TICKET_PRINT')
            }
          })

          const arr_tickets = Array.from(new Set(tickets.map(JSON.stringify))).map(JSON.parse)
          arr_tickets.forEach(element => {
            if (element > this.BookingDetail.length) {
              this.txt_error = this.$t('ERROR_TICKET_PRINT')
            }
          })
          arr_tickets.sort((a, b) => a - b)
          this.ticket_print = [...arr_tickets]
        } else {
          this.txt_error = null
          this.ticket_print = []
        }
      }, 1000)
    },
    key($event) {
      if ($event.charCode === 46) {
        $event.preventDefault()
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@/assets/scss/variables/variables';

#print-boking{
  padding-top: 50px;
  width: 966px;
  margin: auto;
  .item-print{
    padding: 10px;
    border: 2px dashed;
    border-radius: 8px;
    background: #fff;
  }
  .flex {
      display: flex;
      justify-content: center;
      .select-form {
          width: 206px;
          height: 40px !important;
          margin-top: 0 !important;
          margin-right: 20px;
      }
      .QR-code {
          margin-right: 15px;
          max-width: 80px;
          min-width: 80px;
          p {
              word-break: break-all;
          }
      }
      .info-ticket {
          flex-grow: 2;
      }
      .icon-print-ic {
          position: relative;
          top: 2px;
      }
  }
}
</style>
